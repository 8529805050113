@charset "UTF-8";

.form {
  font-family: Roboto;
  font-size: 1rem;
}

* {
  box-sizing: border-box;
}

.form-page * {
  position: relative;
}

#taxform {
  display: inline-block;
}

#taxform * {
  line-height: 1em;
}

.showdraft::before {
  z-index: 999999;
  content: 'DRAFT';
  font-size: 22rem;
  font-weight: 400;
  opacity: 0.2;
  position: absolute;
  top: 35rem;
  left: 60%;
  transform: translate(-60%) rotate(-35deg);
  pointer-events: none;
  -webkit-text-stroke: 4px black;
  -webkit-text-fill-color: transparent;
}

@media only screen and (min-width: 2100px) {
  .formContainer {
    overflow-x: auto;
    max-width: auto;
    margin-left: 0px;
    margin-right: auto;
  }
}

@media only screen and (max-width: 2099px) {
  .formContainer {
    overflow-x: auto;
    max-width: auto;
    margin-left: 0px;
    margin-right: auto;
  }
}

@media only screen and (max-width: 2020px) {
  .formContainer {
    overflow-x: auto;
    max-width: auto;
    margin-left: 0px;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1920px) {
  .formContainer {
    overflow-x: auto;
    max-width: auto;
    margin-left: 0px;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1800px) {
  .formContainer {
    overflow-x: auto;
    max-width: auto;
    margin-left: 0px;
    margin-right: auto;
  }
}

.lockedField {
  background-color: lightgray !important;
}

.formContainer {
  overflow-x: hidden;
  width: 100%;
  padding-top: 30px;
  overflow: auto;
  max-height: 93vh;
  height: 93vh;
  position: relative;
}

.form-page {
  border-radius: 3.8px;
  background-color: #ffffff;
  border: 1px solid #ebedf8;
  border-left: none;
  max-width: auto;
  display: grid;
  grid-template-columns: repeat(86, 17px);
  grid-auto-rows: minmax(auto, auto);
  padding-top: 15px;
  margin-bottom: 5em;
  padding-bottom: 15px;
  position: relative;
}

.form-landscape {
  grid-template-columns: repeat(113, 17px);
}

.form-section-item {
  grid-template-columns: repeat(86, 17px);
  grid-auto-rows: minmax(auto, auto);
}

.form .form-section-item:not(.form-element) {
  position: unset;
}

.form-cellItem .form_field:not(read-only) {
  vertical-align: sub;
  background-color: var(--background, #f3f9ff) !important;
  border: 1px solid #8fc3ff;
  border-radius: 2px;
  /* Eventually want padding here but will have to increase input field size */
}

.form-cellItem .form_field:read-only {
  vertical-align: sub;
  background-color: #ebedf8 !important;
  border: 1px solid #ebedf8;
  /* Eventually want padding here but will have to increase input field size */
}

.box-text-inner-item {
  /* font-size: 1rem; */
  letter-spacing: 1px;
}

.form-cellItem input {
  /* font-size: 1rem; */
  letter-spacing: 0.5px;
  max-height: 26px;
  padding-left: 2px;
  padding-right: 2px;
  text-transform: uppercase;
  width: 100%;
  z-index: 1;
}

.form_field[required]:invalid {
  background-color: var(--background, #f3f9ff) !important;
  border: 1px solid #f0a6a9;
}

.form-element .form-checkbox-input {
  text-align: center;
}

.form-cellItem input:focus {
  background-color: white !important;
  z-index: 100;
  border: 1px solid #0077ff;
  outline-offset: 2px;
}

.form-pagetitle {
  display: flex;
  font-size: 1.7em;
  justify-content: center;
  margin-bottom: 10px;
}

.form-textitem {
  white-space: pre;
}

.form-element {
  margin-bottom: 8px;
}

.form-line-element {
  border-top: 1px solid #0000001a;
  border-left: 1px solid #0000001a;
  z-index: 2;
}

.form-button-element {
  display: block;
  width: 100%;
  z-index: 10;
  background-color: #ffffff;
  color: #0077ff;
  border: 1px solid #0077ff;
}

.form-button-icon {
  display: block;
  width: 100%;
  z-index: 10;
  background-color: #fefefe;
  border: 1px solid #ebedf8;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
}

.form-button-icon-active::after {
  font-family: arial;
  background-color: #0077ff;
  content: '\2714';
  position: absolute;
  z-index: 11;
  left: 1rem;
  top: 1rem;
  color: #ffffff;
  height: 1.25rem;
  width: 1rem;
  border-radius: 10px;
  padding-top: 0.25rem;
}

.form-button-icon-active-wide::after {
  font-family: arial;
  background-color: #0077ff;
  content: '\2714';
  position: relative;
  z-index: 11;
  left: 251px;
  top: -63px;
  color: #ffffff;
  height: 15.77px;
  width: 15.77px;
  border-radius: 10px;
}

.form-button-icon-active {
  font-family: arial;
  background-color: #d7ecf7;
  border: 1px solid #0077ff;
  position: relative;
}

.form-button-element:hover {
  background-color: rgb(162, 205, 253);
}

.bgColor {
  background-color: gray;
  background: linear-gradient(90deg, gray, white);
}

.modal {
  top: 15%;
  outline: none;
}

.error {
  box-shadow: 0px 0px 0px 2px #cc0000;
}

.hide {
  display: none;
}

/* don't show arrows on input fields that have dropdowns */
input::-webkit-calendar-picker-indicator {
  display: none;
}

.form-element-box_2 {
  z-index: 1;
}

.form-element-box_1 {
  margin-bottom: 1px;
}

/* Should eventually be part of a theme - for now use important */
.form-element.shade-element {
  background: white !important;
  opacity: 0;
  /* effectively disable shade items */
  /* move them out of the way, we can't remove them from the form for spacing reasons */
  transform: translate(0em, -9000em) !important;
}

/* Derived from https://www.w3schools.com/howto/howto_js_snackbar.asp */
/* The snackbar - position it at the top and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Visible on click */
  min-width: 250px;
  margin-left: -125px; /* min-width halved */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 5;
  left: 50%;
  top: 60px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    top: 30px;
    opacity: 0;
  }

  to {
    top: 60px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 30px;
    opacity: 0;
  }

  to {
    top: 60px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 60px;
    opacity: 1;
  }

  to {
    top: 30px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 60px;
    opacity: 1;
  }

  to {
    top: 30px;
    opacity: 0;
  }
}

.form-dottext-element {
  overflow: hidden;
  white-space: nowrap;
}

/*
DotTextItem's seem to have a width that usually has '....' appended to fill in remainder width that
the text doesn't fill up
*/
.form-dottext-element::after {
  content: '......................................................................................................................................';
}

.form-hline {
  margin-bottom: 22px;
}

.form-section-button {
  background-color: Transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  padding-right: 15px;
  transform: scale(1, 1.3);
}

.form-section-button:hover {
  background-color: rgb(143, 194, 253);
}

.form-section-button:focus {
  outline: none;
}

input[type='checkbox'] + span {
  transform: translate(-1px, -21px);
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: -1;
  display: block;
  border-radius: 4px;
  background-color: #f3f9ff;
  border: 1px solid #ebedf8;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

input[type='checkbox'] + span:hover {
  background-color: #0661c8;
}

input[type='checkbox']:hover + span {
  background-color: #0661c8;
}

input[type='checkbox']:checked + span span {
  transform: translate(5px, 1px) rotate(45deg);
  width: 5px;
  height: 11px;
  z-index: 1;
  position: absolute;
  display: block;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: translate(4px, 0px) rotate(45deg);
  -ms-transform: translate(4px, 0px) rotate(45deg);
  transform: translate(4px, 0px) rotate(45deg);
}

input[type='checkbox']:checked + span {
  background-color: #0077ff;
}

input[type='checkbox'] {
  opacity: 0;
}

datalist * {
  color: #0077ff;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
}

.collapse {
  visibility: hidden;
}

.collapse.in {
  visibility: visible;
}

.form-section-button:before {
  color: #0077ff;
  content: '\000AB';
  display: inline-block;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  outline: none;
}

.form-section-button.collapsed:before {
  color: #0077ff;
  content: '\000AB';
  display: inline-block;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin-left: -3px;
  outline: none;
}

.rowC {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: #f1f3f5;
}

#clientNameInfo {
  max-width: 32px;
  max-height: 32px;
}

.saveAndCloseButton {
  border-radius: 4px;
  background: #0077ff !important;
  color: white;
  font-size: 14px;
  line-height: 16px;
  margin-left: 1em !important;
  height: 16px;
  outline: 0px !important;
}

.saveAndCloseButton::after {
  content: '   \25BC';
  white-space: pre;
}

.form-hyperlink-element {
  border: none;
  z-index: 1;
  text-decoration: underline;
}

.incomeInformation {
  padding: 15px;
  margin-left: 1em;
}

.refundInfoHeaderPositive::before {
  content: '$';
}

.refundInfoHeaderNegative::before {
  content: '-$';
}

.bodyStyleOverflowHidden {
  overflow-y: hidden;
  height: 100%;
}

.box-text-item {
  border: 1px solid #0077ff;
}
